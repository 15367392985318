import '@pmi.libs/react-common/dist/index.css';
import 'assets/styles/globals.scss';
import { DOM_ELEM_ID } from 'contants';
import { initClarity } from 'libs/telemetry';
import ReactDOM from 'react-dom/client';
import AuthenticationService from 'services/AuthenticationService';
import { attachVersionToDOM } from 'utils';

import { App } from './App';

(async function startApplication() {
  const root = ReactDOM.createRoot(
    document.getElementById(DOM_ELEM_ID.ROOT) as HTMLElement
  );

  attachVersionToDOM();

  initClarity();

  await AuthenticationService.init();

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js');
  }

  root.render(<App />);
})();
